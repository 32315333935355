import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import { AuthContext } from './AuthProvider';
import AdminRoute from './components/AdminRoute';
import Authentication from './components/Authentication';
import Evaluation from './components/employee/Evaluation';
import EvaluationDetails from './components/admin/EvaluationDetails';
import EvaluationList from './components/employee/EvaluationList';
import TopBar from './components/TopBar';
import UserAssociations from './components/admin/UserAssociations';
import UserList from './components/admin/UserList';
import UserRegister from './components/UserRegister';
import UserRegistration from './components/admin/UserRegistration';
import UserRoute from './components/UserRoute';

function App() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Router>
      {isAuthenticated && <TopBar />}

      <Box sx={{ pt: 5 }}>
        <Container>
          <Routes>
            <Route path="/" element={<Authentication />} />
            <Route path="/login" element={<Authentication />} />
            <Route path="/registro" element={<UserRegister />} />

            <Route path="/colaboradores" element={<AdminRoute><UserList /></AdminRoute>} />
            <Route path="/colaboradores/novo" element={<AdminRoute><UserRegistration /></AdminRoute>} />
            <Route path="/colaboradores/:id/relacoes" element={<AdminRoute><UserAssociations /></AdminRoute>} />
            <Route path="/colaboradores/:id/avaliacao" element={<AdminRoute><EvaluationDetails /></AdminRoute>} />

            <Route path="/avaliacoes" element={<UserRoute><EvaluationList /></UserRoute>} />
            <Route path="/avaliacoes/:employeeId" element={<UserRoute><Evaluation /></UserRoute>} />
          </Routes>
        </Container>
      </Box>
    </Router>
  );
}

export default App;
