import { firestore } from '../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

const UserAssociations = () => {
  const { id: userId } = useParams();

  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedPeers, setSelectedPeers] = useState([]);
  const [selectedSubordinates, setSelectedSubordinates] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await firestore.collection('employees').get();
      const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchUser = async () => {
        const userDoc = await firestore.collection('employees').doc(userId).get();
        const userData = userDoc.data();
        setUser(userData);

        if (userData) {
          setSelectedManager(userData.manager || '');
          setSelectedPeers(userData.peers || []);
          setSelectedSubordinates(userData.subordinates || []);
        }
      };

      fetchUser();
    }
  }, [userId]);

  const handleManagerChange = (e) => {
    setSelectedManager(e.target.value);
  };

  const handlePeerChange = (e) => {
    const { value, checked } = e.target;
    let updatedPeers = [...selectedPeers];

    if (checked) {
      updatedPeers.push(value);
    } else {
      updatedPeers = updatedPeers.filter(peer => peer !== value);
    }

    setSelectedPeers(updatedPeers);
  };

  const handleSubordinateChange = (e) => {
    const { value, checked } = e.target;
    let updatedSubordinates = [...selectedSubordinates];

    if (checked) {
      updatedSubordinates.push(value);
    } else {
      updatedSubordinates = updatedSubordinates.filter(subordinate => subordinate !== value);
    }

    setSelectedSubordinates(updatedSubordinates);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userRef = firestore.collection('employees').doc(userId);

    await userRef.update({
      manager: selectedManager,
      peers: selectedPeers,
      subordinates: selectedSubordinates,
    });

    // Refresh the user
    const userDoc = await userRef.get();
    setUser(userDoc.data());
    navigate('/colaboradores');
  };

  return (
    <Box sx={{ width: '50%', margin: '0 auto', marginBottom: 10 }}>
      <Box sx={{ marginBottom: 2, textAlign: 'left', width: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/colaboradores">
            Colaboradores
          </Link>

          <Link
            underline="hover"
            color="primary"
            href=""
            aria-current="page"
          >
            Relações
          </Link>
        </Breadcrumbs>
      </Box>

      <Typography variant="h4" gutterBottom component="div">
        Relações de {user?.name}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{marginBottom: 4}}>
          <Typography variant="h5" gutterBottom component="div">
            Superior
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="manager-select-label"
              value={selectedManager}
              onChange={handleManagerChange}
            >
              {users.filter(user => user.isManager).map(user => (
                <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{marginBottom: 4}}>
          <Typography variant="h5" gutterBottom component="div">
            Pares
          </Typography>
          <FormGroup>
            {users.map(user => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPeers.includes(user.id)}
                    onChange={handlePeerChange}
                    value={user.id}
                  />
                }
                label={user.name}
                key={user.id}
              />
            ))}
          </FormGroup>
        </Box>

        <Box sx={{marginBottom: 4}}>
          <Typography variant="h5" gutterBottom component="div">
            Liderados
          </Typography>
          <FormGroup>
            {users.map(user => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSubordinates.includes(user.id)}
                    onChange={handleSubordinateChange}
                    value={user.id}
                  />
                }
                label={user.name}
                key={user.id}
              />
            ))}
          </FormGroup>
        </Box>

        <Button variant="contained" color="primary" type="submit">
          Salvar
        </Button>
      </form>
    </Box>
  );
}

export default UserAssociations;
