import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Menu as MenuIcon,
  AccountCircle,
  Inbox as InboxIcon,
  Mail as MailIcon,
  Groups as GroupsIcon,
} from '@mui/icons-material';

import { AuthContext } from '../AuthProvider';
import LogoutButton from './LogoutButton';

const TopBar = () => {
  const { isAdmin, isAuthenticated, username } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(isOpen);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {isAdmin && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h5" component="a" href="/avaliacoes" sx={{ color: 'inherit', textDecoration: 'none' }}>
            Online Applications
          </Typography>

          {isAuthenticated && (
            <div style={{ flexGrow: 1 }}></div>
          )}
          {isAuthenticated && (
            <Box >
              {username.toUpperCase()}

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <LogoutButton />
                </MenuItem>
              </Menu>
            </Box >
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250, paddingTop: 5 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem key="colaboradores" disablePadding>
                <ListItemButton component="a" href="/colaboradores">
                  <ListItemIcon><GroupsIcon /></ListItemIcon>
                  <ListItemText primary="Colaboradores" />
                </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default TopBar;
