import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase';
import { AuthContext } from '../../AuthProvider';

import {
  Box,
  CircularProgress,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
} from '@mui/material';

const EvaluationList = () => {
  const { uid, username } = useContext(AuthContext);
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState('');
  const [pendingOnly, setPendingOnly] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRelatedEmployees = async () => {
      if (username) {
        const userRef = firestore.collection('employees').doc(username);
        const userSnapshot = await userRef.get();

        if (userSnapshot.exists) {
          const userData = userSnapshot.data();
          const relatedEmployeesIds = Array.from(new Set([username, ...userData.peers, ...userData.subordinates]));
          if (userData.manager) { relatedEmployeesIds.push(userData.manager); }

          const employeesData = [];
          for (const id of relatedEmployeesIds) {
            let employeeData = JSON.parse(localStorage.getItem(id));

            if (!employeeData) {
              const employeeSnapshot = await firestore.collection('employees').doc(id).get();
              employeeData = {
                id: employeeSnapshot.id,
                ...employeeSnapshot.data(),
              };
            }

            if (!employeeData?.score) {
              const evaluationSnapshot = await firestore.collection('evaluations').doc(`${uid}-${id}`).get();
              const evaluationData = evaluationSnapshot.exists ? evaluationSnapshot.data().scores : null;

              employeeData = {
                ...employeeData,
                evaluated: !!evaluationData,
                score: evaluationData,
              };
            }

            localStorage.setItem(id, JSON.stringify(employeeData));

            employeesData.push(employeeData);
          }

          setEmployees(employeesData);
        }
      }

      setLoading(false);
    };

    fetchRelatedEmployees();
  }, [username, uid]);

  const filteredEmployees = employees
    .filter(employee => employee.name?.toLowerCase().includes(search.toLowerCase()))
    .filter(employee => !pendingOnly || !employee.evaluated)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
      <Typography variant="h4" component="div">
        Avaliações
      </Typography>

      {loading && (
        <Box sx={{ marginTop: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="search"
            label="Procure..."
            name="search"
            autoComplete="search"
            autoFocus
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Checkbox checked={pendingOnly} onChange={e => setPendingOnly(e.target.checked)} color="primary" />
          <label>Avaliações pendentes</label>
          {filteredEmployees.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Interação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredEmployees.map(({ evaluated, id, name, score }) => (
                    <TableRow
                      key={id}
                      onClick={() => {
                        if (!evaluated) {
                          navigate(`/avaliacoes/${id}`);
                        }
                      }}
                      sx={{
                        textDecoration: 'none',
                        cursor: evaluated ? 'default' : 'pointer',
                        backgroundColor: evaluated ? 'rgb(237, 247, 237)' : 'inherit',
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {name}
                      </TableCell>
                      <TableCell align="center">{score?.proximidade || ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" component="div">
              No employees to evaluate.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default EvaluationList;
