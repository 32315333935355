import { useParams, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Link, Rating, TextField, Typography } from '@mui/material';

import { firestore } from '../../firebase';
import { AuthContext } from '../../AuthProvider';

const ratingExplanations = [
  "★ : Quando responder não para todas as “pergunta de apoio”.",
  "★★ : Quando responder sim somente em uma única “pergunta de apoio”.",
  "★★★ : Quando responder sim para duas “perguntas de apoio”.",
  "★★★★ : Quando responder sim para todas as “perguntas de apoio”, com alguma dúvida.",
  "★★★★★ : Quando responder sim para todas as “pergunta de apoio” com certeza absoluta."
];

const initialCompetencies = [
  {
    id: 'proximidade',
    label: 'Nível de interação com o avaliado',
    questions: [
      { text: 'Quão frequente é a sua interação e envolvimento com o trabalho deste colaborador?' }
    ],
    maxRating: 3,
    ratingExplanations: [
      "★ : Minhas interações e envolvimento com o trabalho deste colaborador são esporádicas..",
      "★★ : Regularmente interajo e me envolvo com o trabalho deste colaborador.",
      "★★★ : Interajo e me envolvo com o trabalho deste colaborador de forma intensa e frequente.",
    ]
  },
  {
    id: 'comunicacao',
    label: 'Comunicação',
    questions: [
      { text: 'O colaborador consegue expressar suas ideias de forma clara e concisa, facilitando o entendimento por parte dos demais colegas de equipe?' },
      { text: 'O colaborador demonstra habilidade para ouvir atentamente e compreender as opiniões e necessidades dos colegas de trabalho?' },
      { text: 'O colaborador é capaz de adaptar seu estilo de comunicação para diferentes públicos e situações, garantindo a efetividade da mensagem transmitida?' },
    ],
    ratingExplanations,
  },
  {
    id: 'proatividade',
    label: 'Pró-atividade',
    questions: [
      { text: 'O colaborador costuma tomar a iniciativa para resolver problemas ou desafios antes que sejam apontados por outros?' },
      { text: 'O colaborador demonstra disposição para aprender e adotar novas tarefas ou responsabilidades que vão além de suas obrigações regulares?' },
      { text: 'O colaborador antecipa necessidades e propõe soluções ou melhorias de maneira independente, sem precisar de constante supervisão?' },
    ],
    ratingExplanations,
  },
  {
    id: 'responsabilidade',
    label: 'Responsabilidade',
    questions: [
      { text: 'O colaborador assume a responsabilidade pelos erros cometidos e toma medidas para corrigi-los sem buscar desculpas ou culpar os outros?' },
      { text: 'O colaborador demonstra compromisso com os prazos e metas estabelecidos, fazendo todo o possível para cumpri-los?' },
      { text: 'O colaborador trata as tarefas e projetos atribuídos como se fossem seus, demonstrando um alto nível de responsabilidade pelo resultado final?' },
    ],
    ratingExplanations,
  },
  {
    id: 'independenciaTecnica',
    label: 'Independência Técnica',
    questions: [
      { text: 'O colaborador é capaz de resolver desafios técnicos por conta própria, sem necessidade de assistência constante?' },
      { text: 'O colaborador demonstra ter um bom entendimento e domínio das ferramentas e tecnologias usadas em seu trabalho?' },
      { text: 'Quando se depara com um problema técnico desconhecido, o colaborador busca ativamente soluções e aprende novas habilidades para superá-lo, sem precisar de direcionamento frequente?' },
    ],
    ratingExplanations,
  },
  {
    id: 'autoDesenvolvimento',
    label: 'Auto-desenvolvimento',
    questions: [
      { text: 'O colaborador busca proativamente oportunidades para aprender e desenvolver novas habilidades que são relevantes para seu papel e crescimento na empresa?' },
      { text: 'O colaborador aplica os aprendizados e feedbacks recebidos para melhorar seu desempenho e eficiência no trabalho?' },
      { text: 'O colaborador demonstra uma atitude de aprendizado contínuo, mantendo-se atualizado sobre as novas tendências e desenvolvimentos em sua área de especialização?' },
    ],
    ratingExplanations,
  },
  {
    id: 'conhecimentoTecnico',
    label: 'Aplicação prática do conhecimento técnico',
    questions: [
      { text: 'O colaborador é capaz de aplicar efetivamente suas habilidades e conhecimentos técnicos na realização de suas tarefas diárias, produzindo resultados de alta qualidade?' },
      { text: 'O colaborador demonstra habilidade em utilizar o conhecimento técnico para resolver problemas complexos e desafios inerentes ao seu papel?' },
      { text: 'O colaborador consegue adaptar e aplicar seu conhecimento técnico para diferentes situações, mostrando flexibilidade e inovação na execução de suas tarefas?' },
    ],
    ratingExplanations,
  },
  {
    id: 'contribuicao',
    label: 'Contribuição para os Objetivos da Equipe',
    questions: [
      { text: 'O colaborador realiza suas tarefas e responsabilidades de forma consistente, contribuindo para o alcance dos objetivos da equipe?' },
      { text: 'O colaborador demonstra um forte compromisso com os objetivos da equipe, contribuindo de forma significativa para sua realização?' },
      { text: 'O trabalho do colaborador alinha-se diretamente com os objetivos da equipe, proporcionando um impacto positivo no progresso geral?' },
    ],
    ratingExplanations,
  },
  {
    id: 'colaboracao',
    label: 'Colaboração e Suporte aos Colegas',
    questions: [
      { text: 'O colaborador mostra disponibilidade e disposição para ajudar os colegas de trabalho quando necessário?' },
      { text: 'O colaborador contribui de maneira significativa para um ambiente de trabalho colaborativo, compartilhando conhecimento e ideias com a equipe?' },
      { text: 'O colaborador oferece suporte aos colegas de trabalho, auxiliando na resolução de problemas e desafios, quando solicitado?' },
    ],
    ratingExplanations,
  },
  {
    id: 'adaptabilidade',
    label: 'Adaptabilidade e Resolução de Problemas',
    questions: [
      { text: 'O colaborador demonstra capacidade de se adaptar rapidamente a mudanças no ambiente de trabalho, como alterações nas tarefas ou processos?' },
      { text: 'O colaborador é capaz de encontrar soluções eficazes para os desafios e problemas que surgem durante a realização do seu trabalho?' },
      { text: 'O colaborador mantém sua produtividade e qualidade de trabalho mesmo diante de situações adversas ou imprevistas?' },
    ],
    ratingExplanations,
  },
];

const Evaluation = () => {
  const { uid: userId, username } = useContext(AuthContext);
  const { employeeId: evaluateeUsername } = useParams();
  const isSelfEvaluation = username === evaluateeUsername;
  const navigate = useNavigate();

  const [competencyIndex, setCompetencyIndex] = useState(0);
  const [evaluateeName, setEvaluateeName] = useState('');
  const [competencies, setCompetencies] = useState(isSelfEvaluation ? initialCompetencies.slice(1) : initialCompetencies);
  const [feedback, setFeedback] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setCompetencies(isSelfEvaluation ? initialCompetencies.slice(1) : initialCompetencies);
  }, [username, evaluateeUsername, isSelfEvaluation]);

  useEffect(() => {
    const fetchEvaluatee = async () => {
      if (!evaluateeUsername) { return; }
      const userRef = firestore.collection('employees').doc(evaluateeUsername);
      const userSnapshot = await userRef.get();

      if (userSnapshot.exists) {
        const data = userSnapshot.data();
        setEvaluateeName(data.name);
      }
    }

    fetchEvaluatee();
  }, []);

  const handleRatingChange = (rating) => {
    const newCompetencies = competencies.map((c, index) => {
      if (index === competencyIndex) {
        return {...c, rating};
      }
      return c;
    });
    setCompetencies(newCompetencies);
  };

  const handleNextCompetency = () => {
    if (!competencies[competencyIndex].rating) {
      setErrors(['Requerido']);
      return;
    }

    if (competencyIndex === competencies.length - 1 && !showFeedback) {
      setShowFeedback(true);
      setErrors([]);
    } else if (showFeedback) {
      handleSubmitEvaluation();
    } else {
      setCompetencyIndex(competencyIndex + 1);
      setErrors([]);
    }
  };

  const handlePreviousCompetency = () => {
    if (competencyIndex > 0) {
      setCompetencyIndex(competencyIndex - 1);
    }
  };

  const handleSubmitEvaluation = async () => {
    try {
      const scores = {};
      competencies.forEach(c => {
        scores[c.id] = c.rating;
      });

      const ref = firestore.collection('evaluations').doc(userId + '-' + evaluateeUsername);
      await ref.set({
        ...(feedback ? { feedback } : {}),
        evaluatee: firestore.doc(`employees/${evaluateeUsername}`),
        evaluator: firestore.doc(`employees/${username}`),
        scores,
      })

      setIsSubmitted(true);
      navigate('/avaliacoes');
    } catch (error) {
      console.error('Error submitting evaluation: ', error);
      alert('Error submitting evaluation. Please try again.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Show success message when the form is submitted successfully */}
      {isSubmitted && <Typography color="success.main">Evaluation submitted successfully.</Typography>}

      <Box sx={{ marginBottom: 2, textAlign: 'left', width: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/avaliacoes">
            Avaliações
          </Link>

          <Link
            underline="hover"
            color="primary"
            href=""
            aria-current="page"
          >
            {evaluateeName}
          </Link>
        </Breadcrumbs>
      </Box>

      {showFeedback ? (
        <Box sx={{ marginBottom: 4, marginTop: 2, textAlign: 'center', width: '100%' }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
            Quer dar um feedback adicional? (opcional)
          </Typography>
          <TextField
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </Box>
      ) : (
        <>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            {competencies[competencyIndex].label}
          </Typography>
          {competencies[competencyIndex].questions.map((q, i) => (
            <Box key={i} sx={{ marginBottom: 2, textAlign: 'left', width: '100%' }}>
              <Typography variant="h6">
                {`${i + 1}. ${q.text}`}
              </Typography>
            </Box>
          ))}

          <Box sx={{ marginBottom: 4, marginTop: 2, textAlign: 'center', width: '100%' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
              Avalie essa competência:
            </Typography>
            <Rating
              name="rating"
              size="large"
              value={competencies[competencyIndex].rating || 0}
              onChange={(_event, newValue) => handleRatingChange(newValue)}
              max={competencies[competencyIndex].maxRating || 5}
            />
            {errors.map((error, i) => (
              <Typography key={i} variant="body2" color="error">{error}</Typography>
            ))}
          </Box>

          <Box sx={{ marginBottom: 2, textAlign: 'left', width: '100%' }}>
            {competencies[competencyIndex].ratingExplanations.map((explanation, index) => (
              <Typography
                key={index}
                variant="body1"
                color={competencies[competencyIndex].rating === index + 1 ? 'primary' : 'textPrimary'}
              >
                {explanation}
              </Typography>
            ))}
          </Box>
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button onClick={handlePreviousCompetency} disabled={competencyIndex === 0 || showFeedback}>Previous</Button>
        <Button
          onClick={handleNextCompetency}
          variant="contained"
          color="primary"
          disabled={(!competencies[competencyIndex].rating && !showFeedback) ? true : false}
        >
          {showFeedback ? 'Submit' : (competencyIndex === competencies.length - 1 ? 'Next' : 'Next')}
        </Button>
      </Box>
    </Box>
  );
};

export default Evaluation;
