import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { auth } from '../firebase';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed: ', error.message);
    }
  };

  return (
    <Button variant="text" onClick={handleLogout}>Sair</Button>
  );
};

export default LogoutButton;
