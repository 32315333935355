import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Typography, TextField, Button, Container, Grid, Box } from '@mui/material';

import { AuthContext } from '../AuthProvider';

const Authentication = () => {
  const { isAuthenticated, logIn } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const authenticateUser = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError('All fields are required.');
      return;
    }

    try {
      await logIn(username, password);
    } catch (error) {
      setError('Invalid username or password.');
    }
  };

  if (isAuthenticated) {
    navigate('/avaliacoes'); // Or any other path
  }

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img
          src="https://www.onlineapp.com.br/wp-content/uploads/2021/04/cropped-Logo_OnlineApp.png"
          alt="OnlineApp Logo"
        />
        <Box component="form" onSubmit={authenticateUser} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="username"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          {error && (
            <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/registro" variant="body2">
                Primeiro acesso? Clique aqui
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Authentication;
