import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

const UserList = () => {
  const pageSize = 20;
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [firstVisible, setFirstVisible] = useState(null);
  const [previousPageStarts, setPreviousPageStarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchUsers = async (direction) => {
    setLoading(true);
    let query = firestore.collection('employees').orderBy('username').limit(pageSize + 1);

    if (searchTerm !== '') {
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      query = query.startAt(lowercaseSearchTerm).endAt(lowercaseSearchTerm + '\uf8ff');
    }

    if (direction === 'next' && lastVisible) {
      query = query.startAfter(lastVisible);
    } else if (direction === 'previous' && previousPageStarts.length > 0) {
      query = query.startAt(previousPageStarts[previousPageStarts.length - 1]);
      setPreviousPageStarts(previousPageStarts.slice(0, -1));
    }

    const usersSnapshot = await query.get();
    const firstVisibleSnapshot = usersSnapshot.docs[0];
    const lastVisibleSnapshot = usersSnapshot.docs[usersSnapshot.docs.length - 1];
    const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if(usersSnapshot.docs.length > pageSize) {
      usersList.pop();
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    setFirstVisible(firstVisibleSnapshot);
    setLastVisible(lastVisibleSnapshot);
    setUsers(usersList);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchUsers();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleNextClick = () => {
    setPreviousPageStarts([...previousPageStarts, firstVisible]);
    fetchUsers('next');
  };

  const handlePreviousClick = () => {
    fetchUsers('previous');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Colaboradores
          </Typography>
          <Button component={Link} to="/colaboradores/novo" variant="contained" color="primary" size="small">
            Novo usuário
          </Button>
        </Box>

        <Box sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            fullWidth
            id="search"
            label="Pesquisar"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Box>

        <Box sx={{paddingTop: 4}}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {users.map(user => (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                        <Button component={Link} to={`/colaboradores/${user.id}/relacoes`} size="small">
                          Relações
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 4 }}>
            <Button variant="contained" color="primary" onClick={handlePreviousClick} disabled={previousPageStarts.length === 0}>
              Anterior
            </Button>

            <Button variant="contained" color="primary" onClick={handleNextClick} disabled={!hasMore}>
              Próximo
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default UserList;
