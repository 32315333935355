import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Grid, Box, Typography } from '@mui/material';
import { auth, firestore } from '../firebase';

const UserRegister = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError('All fields are required.');
      return;
    }

    const employeesRef = firestore.collection('employees');
    const querySnapshot = await employeesRef.get();

    let userExists = false;

    querySnapshot.forEach((doc) => {
      if (doc.id === username) {
        userExists = true;
      }
    });

    if (!userExists) {
      setError('Usuário não encontrado');
      return;
    }

    // Create user
    const email = `${username}@onlineapp.com.br`;
    try {
      await auth.createUserWithEmailAndPassword(email, password);
      // await logIn(username, password);
      navigate('/avaliacoes');
    } catch (error) {
      setError('Usuário já cadastrado');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img
          src="https://www.onlineapp.com.br/wp-content/uploads/2021/04/cropped-Logo_OnlineApp.png"
          alt="OnlineApp Logo"
        />
        <Box component="form" onSubmit={handleRegister} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Usuário OnlineApp"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="username"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="password"
                label="Cadastre uma senha"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          {error && (
            <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            PRIMEIRO ACESSO
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Já possui uma conta? Entre aqui
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default UserRegister;
