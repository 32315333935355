import React, { useState } from 'react';
import { firestore } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Link,
} from '@mui/material';

const UserRegistration = () => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [isManager, setIsManager] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();

  const registerUser = async (e) => {
    e.preventDefault();

    if (!username || !name) {
      setError('All fields are required.');
      return;
    }

    const userRef = firestore.collection('employees').doc(username);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      setError('The username is already taken.');
      return;
    }

    await userRef.set({
      name,
      username,
      isManager,
    });

    setSuccess('User was registered successfully.');
    setUsername('');
    setName('');
    setIsManager(false);

    navigate('/colaboradores');
  };

  return (
    <Box
      component="form"
      onSubmit={registerUser}
      noValidate
      sx={{
        mt: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        maxWidth: 400,
        mx: 'auto',
        px: 3,
      }}
    >
      <Box sx={{ marginBottom: 2, textAlign: 'left', width: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/colaboradores">
            Colaboradores
          </Link>

          <Link
            underline="hover"
            color="primary"
            href=""
            aria-current="page"
          >
            Novo
          </Link>
        </Breadcrumbs>
      </Box>

      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nome"
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoComplete="name"
        autoFocus
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Usuário"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        autoComplete="username"
      />

      <FormControlLabel
        control={<Checkbox value={isManager} onChange={(e) => setIsManager(e.target.checked)} color="primary" />}
        label="É gerente?"
      />

      {error && (
        <Typography variant="body2" style={{ color: 'red' }}>
          {error}
        </Typography>
      )}

      {success && (
        <Typography variant="body2" style={{ color: 'green' }}>
          {success}
        </Typography>
      )}

      <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
        Registrar
      </Button>
    </Box>
  );
};

export default UserRegistration;
