import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const AdminRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          setIsAdmin(!!idTokenResult.claims.isAdmin);
          setIsLoading(false);
        });
      } else {
        navigate("/login");
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    navigate("/login");
    return null;
  }

  return children;
};

export default AdminRoute;
