// firebase.js

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDwFWv6bvnWJaBDLoOevtMb59E3KKhmPLc",
  authDomain: "onlineapp-smart-hmg.firebaseapp.com",
  projectId: "onlineapp-smart-hmg",
  storageBucket: "onlineapp-smart-hmg.appspot.com",
  messagingSenderId: "813801793949",
  appId: "1:813801793949:web:0be86a4249763cb75c6774",
  measurementId: "G-1JLY91M34E"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const auth = firebase.auth();
const firestore = firebase.firestore();

// Export the auth and firestore instances
export { auth, firestore };
