import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { firestore } from '../../firebase';


const EvaluationDetails = () => {
  const { id: employeeId } = useParams();
  const [evaluationDetails, setEvaluationDetails] = useState(null);

  useEffect(() => {
    async function fetchDetails() {
      const employeeRef = firestore.collection('employees').doc(employeeId);
      const evaluations = await firestore.collection('evaluations')
        .where('evaluatee', '==', employeeRef)
        .get();

      if (evaluations.empty) {
        setEvaluationDetails({ error: 'No evaluations found for this employee.' });
        return;
      }

      let selfEvaluation = null;
      let peerEvaluations = [];

      evaluations.forEach(doc => {
        const data = doc.data();
        if (data.selfEvaluation) {
          selfEvaluation = data.competencies;
        } else {
          peerEvaluations.push(data.competencies);
        }
      });

      if (!selfEvaluation) {
        setEvaluationDetails({ error: 'Self-evaluation not found for this employee.' });
        return;
      }

      if (peerEvaluations.length === 0) {
        setEvaluationDetails({ error: 'Peer evaluations not found for this employee.' });
        return;
      }

      const peerAverage = Object.keys(selfEvaluation).reduce((accum, curr) => {
        const total = peerEvaluations.reduce((sum, currEval) => sum + currEval[curr], 0);
        const average = total / peerEvaluations.length;
        return { ...accum, [curr]: average };
      }, {});

      setEvaluationDetails({
        employeeName: employeeId, // You might want to fetch the actual employee name.
        competencies: Object.entries(selfEvaluation).map(([name, selfScore]) => ({
          id: name,
          name,
          selfScore,
          peerScore: peerAverage[name],
        })),
        totalSelfScore: Object.values(selfEvaluation).reduce((a, b) => a + b, 0),
        totalPeerScore: Object.values(peerAverage).reduce((a, b) => a + b, 0),
      });
    }

    fetchDetails();
  }, [employeeId]);

  if (!evaluationDetails) {
    return <div>Loading...</div>;
  }

  if (evaluationDetails.error) {
    return <div>{evaluationDetails.error}</div>;
  }

  const renderScore = (selfScore, peerScore) => {
    const discrepancy = Math.abs(selfScore - peerScore) > 1;
    return (
      <div style={discrepancy ? { backgroundColor: 'red' } : {}}>
        Self: {selfScore}, Peer: {peerScore}
      </div>
    );
  };

  return (
    <div>
      <h1>Evaluation Comparison for Employee {evaluationDetails.employeeName}</h1>
      {evaluationDetails.competencies.map(comp => (
        <div key={comp.id}>
          <h2>{comp.name}</h2>
          {renderScore(comp.selfScore, comp.peerScore)}
        </div>
      ))}
      <h2>Total Score</h2>
      {renderScore(
        evaluationDetails.totalSelfScore,
        evaluationDetails.totalPeerScore
      )}
    </div>
  );
};

export default EvaluationDetails;
